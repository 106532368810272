* {
  background-color: #131313;
  color: white;
  font-family: "DM Serif Text", serif;
  font-weight: 400;
  font-style: normal;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Make the footer stick to the bottom of the page */
#contentContainer {
  min-height: calc(100vh - 84px);
}

h1, h2, .testimonialText {
  text-align: center;
}

.headerText {
  text-align: center;
  font-size: 45px;
  margin: 0;
}

.pageContent {
  width: 90%;
  margin: 0 auto;
}