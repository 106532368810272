#footer {
    text-align: center;
    margin-top: 30px;
}

#footerLink {
    text-decoration: none;
}

#footerLink:hover {
    color: #306595;
    text-decoration: underline;
    cursor: pointer;
}

@media screen and (max-width: 780px) {
    #footer {
        font-size: 2vw;
    }
}