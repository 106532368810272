#navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#logo {
    width: 32%;
    height: auto;
    margin: 10px 20px;
}

#navigation {
    width: 30%;
    display: flex;
    flex-direction: row;
    list-style: none;
    justify-content: space-between;
    align-items: center;
    margin-right: 40px;
}

.menuItem {
    text-decoration: none;
    font-size: 2vw;
}
.menuItem:hover {
    color: rgb(198, 198, 198);
    border-bottom: 1px solid rgb(198, 198, 198);
}

/* Mobile Nav */
#mobileNav {
    display: none;
}
#mobileHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
#mobileLogo {
    width: 40%;
    margin: 10px 10px;
}
#burger div {
    width: 40px;
    height: 4px;
    margin: 8px;
    transition: all 0.3s ease;
    background-color: white;
    border-radius: 10px;
    margin-right: 20px;
}
.mobileItem {
    text-decoration: none;
    font-size: 35px;
    margin: 20px auto;
    background-color: black;
}

/* Animate the hamburger after the handleclick function is called */
.line1click {
    transform: rotate(-45deg) translate(-10px, 8px);
}

.line2click {
    opacity: 0;
}

.line3click {
    transform: rotate(45deg) translate(-8px, -7px);
}

@media screen and (max-width: 780px) {
    #navbar {
        display: none;
    }
    #mobileNav {
        display: block;
    }
    .navbar {
        width: 100%;
        height: calc(100vh - 80px);
        top: 85px;
        left: -100%;
        opacity: 0;
        position: absolute;
        transition: all 0.5s ease;
        margin-top: 0;
        margin-bottom: 10px;
        background-color: black;
        display: flex;
        flex-direction: column;
    }
    .navbar.active {
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 10000;
    }
}