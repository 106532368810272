label {
    font-size: 20px;
    margin-bottom: 2px;
}

#contactForm {
    display: flex;
    flex-direction: column;
    width: 40%;
}

#contactForm input, textarea {
    background-color: white;
    color: black;
    font-size: 20px;
}

#contactForm textarea {
    resize: none;
    height: 200px;
}

#formBtn {
    width: 100px;
    background-color: #306595;
    border: none;
    border-radius: 3px;
    padding: 10px 10px;
    font-size: 20px;
    margin: 20px 0;
}

#formBtn:hover {
    background-color: #cc9562;
    cursor: pointer;
}

@media screen and (max-width: 780px) {
    #contactForm {
        width: 100%;
    }
}